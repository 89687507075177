(function () {
    angular.module('informaApp')
        .service('NewFeatureCookieService', NewFeatureCookieService);

    function NewFeatureCookieService($http, $cookies) {
        const field = 'lastSeenFeatures';
        const isDismissedField = 'newFeaturePopupDismissed';

        return {
            getLastSeenFeatureIds: () => {
                try {
                    return JSON.parse($cookies.get(field))
                } catch {
                    return null;
                }
            },
            setLastSeenFeatureIds: (ids) => setCookieWithDefaultExpiration(field, JSON.stringify(ids)),
            setDismissedStatus: (isDismissed) => setCookieWithDefaultExpiration(isDismissedField, isDismissed),
            getDismissedStatus: () => $cookies.get(isDismissedField) === (true).toString()
        };

        function setCookieWithDefaultExpiration(name, value) {
            $cookies.put(name, value, { expires: getNextMonthDate() });
        }

        function getNextMonthDate() {
            const currentDate = new Date();
            currentDate.setMonth(currentDate.getMonth() + 1);
    
            return currentDate;
        }
    }
})();
